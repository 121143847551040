#root {
  height: 100%;
  overflow: auto;
  background-color: #f2f2f2;
  font-size: 14px;
  color: #333;

  // 默认样式
  p {
    margin-bottom: 0;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  .c-body-wrap {
    width: 96%;
    height: calc(100vh - 60px);
    min-width: 800px;
    margin: 30px auto 0;
    position: relative;
  }

  .c-left-wrap {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
}
