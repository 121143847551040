@import "./iconfont/iconfont";


//colors
$--color-theme: #ff440a;
$--color-auxiliary-orange: #ff812d;
$--color-auxiliary-blue: #2a8ff7;
$--color-success: #5bc531;
$--color-warn: #f5ad42;
$--color-error:#ed494c;
$--color-white:#fff;
$--color-text-dark:#4a4a4a;
$--color-text-grey:#666;
$--color-text-light:#9b9b9b;
$--color-bg-dark:#444;
$--color-bg-grey:#666;
$--color-bg-light:#f7f8fa;
$--color-line:#dedede;
$--color-divider:#ededef;

//sizes
$--size-line:1px;
$--size-line-bold:2px;
$--size-text-32:32px;
$--size-text-24:24px;
$--size-text-20:20px;
$--size-text-16:16px;
$--size-text-18:18px;
$--size-text-14:14px;
$--size-text-12:12px;

//distances
$--distance-gap-8:8px;
$--distance-gap-16:16px;
$--distance-gap-24:24px;
$--distance-gap-32:32px;
$--distance-gap-40:40px;
$--distance-gap-48:48px;
$--distance-gap-56:56px;
$--distance-gap-64:64px;


//styles
$--style-solid-border:$--size-line solid $--color-line;
$--style-dashed-border:$--size-line dashed $--color-line;


//others
$--page-content-width: 1012px;


//before

$--all-border-thin:1px solid #e8e8e8;
$--border-input:1px solid #d9d9d9;
$--theme-color:#1890ff;
$--color-red: #DB211D;
$--color-text-black:#3c3c3c;
$--color-text-red:#d5312e;

.one-line-text{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.two-line-text{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.table_action_btn{
  color:$--theme-color;
  user-select: none;
  cursor: pointer;
}

.input_wrapper{
  position: relative;
  .input_tip{
    position:absolute;
    left: 5px;
    bottom: -10px;
    line-height: 14px;
    width: 200px;
    color: #EC2200;
  }
}

.iconfont_text{
  font-family: 'iconfont' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}


/*修改滚动条样式*/
div::-webkit-scrollbar{
  width:10px;
  height:10px;
}
div::-webkit-scrollbar-track{
  background: rgb(239, 239, 239);
  border-radius:2px;
}
div::-webkit-scrollbar-thumb{
  background: #e0e0e0;
  border-radius:10px;
}
div::-webkit-scrollbar-thumb:hover{
  background: #999;
}
div::-webkit-scrollbar-corner{
  background: #179a16;
}

.required_title_before{
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.button_line{
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
  border: 1px solid $--theme-color;
  color: $--theme-color;
  text-align: center;
  user-select: none;
  cursor: pointer;
  border-radius: 4px;
}

.required_title{
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
